import './App.css';
import Footer from './Components/footer';
import Header from './Components/header';
import Main from './Components/Main';


function App() {
  return (
    <div className='md:px-4'>
      <Header/>
      <Main/>
      <Footer/>
    </div>
  );
}

export default App;
